.bombas-variant-hero-image {
  display: flex;
  justify-content: center;
  max-width: 120px;
  margin-left: -6px;
}

.bombas-variant-hero-image img,
.bombas-variant-hero-image svg {
  transform: scale(0.75);
}

.bombas-variant-hero-image img {
  border-radius: 100%;
}

.bombas-variant-hero-image span {
  display: none;
}

.bombas-variant-menu {
  padding-left: 0.5rem;
  display: flex;
}

.bombas-variant-checkbox {
  justify-content: center;
}

.bombas-loading-overlay {
  background-color: #154c9ace;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 4rem;
  flex-direction: column;
  font-weight: bold;
}

.bombas-loading-overlay span {
  font-size: 0.75rem;
  margin-top: 2rem;
  padding: 0.25rem;
  background-color: #154c9a;
}
